import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Button, ButtonStates } from "@legacyComponents/Button";
import { theme } from "@willsTheme";
import X from "@assets/images/x-icon.png";
import Facebook from "@assets/images/facebook-icon.png";
import WhatsApp from "@assets/images/whatsapp-icon.png";
import { SITE_URL } from "gatsby-env-variables";

const Container = styled.div`
  max-width: 600px;
  margin: 7vw auto;
  display: flex;
  flex-direction: column;
  gap: 2em;
  @media (max-width: 600px) {
    padding: 1em 2em;
  }
`;

const TitleText = styled.h2`
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1em;
  color: ${theme.colors.Primary03};
`;

const DescriptionContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const TextBlob = styled.p`
  color: ${theme.colors.Font02};
  font-size: 0.85rem;
`;

const ReferralCodeContainer = styled.div`
  & > h2 {
    font-size: 0.9rem;
  }
`;

const ReferralLinkContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr auto;
  gap: 1em;
  & > div {
    align-items: center;
    vertical-align: middle;
    padding: 0.5rem 0.75rem;
    border: solid 1px ${theme.colors.Grey02};
    border-radius: 12px;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    color: ${theme.colors.Font02};
    font-weight: 400;
    font-size: 0.75rem;
    & > p {
    }
  }
  @media (max-width: 450px) {
    display: flex;
    & > button {
      display: none;
    }
  }
`;

const SocialIconsContainer = styled.div`
  display: flex;
  gap: 1em;
  margin-top: 1em;
  @media (max-width: 450px) {
    display: none;
  }
`;

const IconContainer = styled.button`
  border-radius: 50%;
  border: solid 0.777px ${theme.colors.Grey01};
  padding: 0.5em;
  height: 60px;
  width: 60px;
  background-color: white;
  & a > img {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
`;

const MobileShareButtonsContainer = styled.div`
  display: none;
  gap: 0.5em;
  margin-top: 1em;
  @media (max-width: 450px) {
    display: flex;
  }
`;

const BottomTextContainer = styled.div`
  margin-top: 2em;
  & > p {
    max-width: 500px;
  }
  & > div {
    margin-top: 1em;
    width: 60%;
  }
`;

const Referral: React.FC = () => {
  const referralLink = `${SITE_URL}`;

  return (
    <Container>
      <div>
        <TitleText>Thank you!</TitleText>
        <DescriptionContainer>
          <TextBlob>
            Your generosity has helped strengthen and sustain livelihoods in
            communities globally. You can support them further by sharing the
            link to the zakat calculator with your friends and family.
          </TextBlob>
          <TextBlob>
            On average, by sharing the calculator with 3 people you can multiply
            the impact you have by 27.
          </TextBlob>
        </DescriptionContainer>
      </div>
      <ReferralCodeContainer>
        <TitleText>Share now</TitleText>
        <ReferralLinkContainer>
          <div>{referralLink}</div>
          <Button
            text="Copy Link"
            version={ButtonStates.PRIMARY_GHOST_LARGE}
            onClick={async () => {
              toast.info(
                "Link copied! Note: click on a social icon to share with friends on social medial."
              );
            }}
          />
        </ReferralLinkContainer>
        <SocialIconsContainer>
          <IconContainer>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://x.com/intent/tweet?text=${encodeURI(
                "You can pay your Zakat through the Zakat Calculator App by Islamic Finance Guru"
              )}&url=${encodeURIComponent(
                referralLink
              )}&related=IFguru&via=IFguru`}
            >
              <img src={X} alt="x-icon" />
            </a>
          </IconContainer>
          <IconContainer>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/?text=${encodeURIComponent(
                "You can pay your Zakat through the Zakat Calculator App by Islamic Finance Guru. " +
                  referralLink
              )}`}
            >
              <img src={WhatsApp} alt="whatsapp-icon" />
            </a>
          </IconContainer>
          <IconContainer>
            <a target="_blank" href="https://facebook.com" rel="noreferrer">
              <img src={Facebook} alt="facebook-icon" />
            </a>
          </IconContainer>
        </SocialIconsContainer>
        <MobileShareButtonsContainer>
          <Button
            text="Copy Link"
            version={ButtonStates.PRIMARY_GHOST_LARGE}
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(referralLink);
                toast.info(
                  "Link copied! Note: click on a social icon to share with friends on social medial."
                );
              } catch (error) {
                toast.error("Error copying");
              }
            }}
          />
          <Button
            text="Share"
            version={ButtonStates.PRIMARY}
            onClick={async () => {
              if (navigator.share) {
                try {
                  await navigator.share({
                    title: "Easily your Zakat",
                    text: "You can pay your Zakat through the Zakat Calculator App by Islamic Finance Guru.",
                    url: referralLink,
                  });
                } catch (error) {
                  toast.error("Failed to Share");
                }
              }
            }}
          />
        </MobileShareButtonsContainer>
      </ReferralCodeContainer>
    </Container>
  );
};

export default Referral;
